@import '../../views/common/common.scss';
html {
    font-size: 14px;
  }
  .gridContainer {
    max-width: 90%;
    margin: auto;
  }
  .dropDownWrapper {
    display: flex;
    gap: 1rem;
    font-size: 1rem;
  }
  .dropDownWrapper .rmsc .dropdown-container {
    background: #c8102e;
  }
  .dropDownWrapper .multi-select {
    width: 10rem;
    --rmsc-h: 1.5rem;
    --rmsc-radius: 2px;
  }
  .dropDownWrapper {
    h4 {
      margin: 0.5rem 0;
    }
    .dropdown-heading-value span{
      font-size: 0.8rem;
      color: #fff;
      line-height: 1rem;
      display: inherit;
    }
    .dropdown-content {
      font-size: 0.8rem;
      background: #c8102e;
      color: #000;
      max-height: 50px;
      * {
        ::-webkit-scrollbar {
          width: 5px;
        }
        
        ::-webkit-scrollbar-track {
          background-color: darkgrey;
        }
        
        ::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        height: auto;
        margin: 0;
        padding: 0;
        line-height: 1;
      }
      div {
        background: #c8102e;
        margin: 0;
        padding: 0;
        height: auto;
        
      }
    }
  }
  .pieChartContainer {
    path,.recharts-layer {
      outline: none;
    }
    .recharts-pie-sector {
      path {
        cursor: pointer;
      }
    }
    .recharts-layer {
      text {
        fill: #222;
        font-family: HelveticaNeueBold;
      }
      .text_5 {
        transform: translateY(5px);
      }
    }
    .recharts-default-tooltip {
      padding: 1px 8px !important;
      color: #222 !important;
      span {
        display: none;
        &.recharts-tooltip-item-name {
          display: block;
        }
      }
    }
    .piePath {
     transform-origin: center;
    }
    .piePath.activeRow {
      transform: scale(1.05);
    } 
  }
  .chartWrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 300px;
    justify-content: space-between;
    padding-top: .5rem;
    .recharts-responsive-container {
      .custom-tooltip {
        background-color: #fff;
        padding:0 0.4rem;
        border: 1px solid #ddd;
        p {
          font-size: 0.75rem;
          color: #222;
          margin: 0;
          font-family: HelveticaNeueBold;
        }
      }
    }
    .pieChartWrapper {
      width: 40%;
    }
    .barChartWrapper {
      display: flex;
      flex-wrap: nowrap;
      min-width: 700px;
      flex-direction: column;
      position: relative;
      .barChartDivs {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;
        > div {
          width: 50%;
          height: 90%;
          flex-grow: 1;
          position: relative;
          .errorMsg{
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -10px -50px
          }
        }
        
        }
      }
  }
  .barChartWrapper {
    font-family: HelveticaNeueBold;
    h4 {
      margin-bottom: 0;
      font-size: 1.1rem;
      margin-left: 10%;
      @include antoBold;
      color: #c7112d;
    }
    .recharts-text {
      font-size: 0.7rem;
    }
    .recharts-layer {
      line {
        stroke: #ccc;
      }
      text {
        fill: #222;
      }
    }
    .barChatXaxis {
     
    }
  }
  .toggleBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -9rem;
    gap: 0.5rem;
    > span {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      overflow: hidden;
      
    }
    input[type=checkbox]{
      height: 0;
      width: 0;
      visibility: hidden;
    }
    
    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 50px;
      height: 25px;
      background: grey;
      display: block;
      border-radius: 25px;
      position: relative;
    }
    
    label:after {
      content: '';
      position: absolute;
      top: 2.5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 10px;
      transition: 0.3s linear;
    }
    
    input:checked + label {
      background: #bada55;
    }
    
    input:checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
    
    label:active:after {
      width: 30px;
    }
  }
  .marginBreakDownWrapper {
    display: flex;
    justify-content: flex-end;
    > div {
      display: flex;
      flex-direction: column;
     >div {
        display: flex;
        flex-wrap: nowrap;
     }
    }
    .breakDownBox {
     display: flex;
     flex-direction: column;
     width: 10rem;
     height: 4.5rem;
     position: relative;
     justify-content: space-between;
     margin: 0.25rem;
     align-items: center;
     padding: 0.35rem;
     padding-bottom: 0.2rem;
    }
    .topRowBox {
      .breakDownBox {
        &:nth-of-type(1) {
          background-color: #cf9676;
         }
         &:nth-of-type(2) {
          background-color: #ffad7e;
         }
         &:nth-of-type(3) {
          background-color: #fbb9be;
         }
         &:nth-of-type(4) {
          background-color: #f8727d;
         }
      }
    }
    .bottomRowBox {
      .breakDownBox {
        &:nth-of-type(1) {
          background-color: #f1ba0a;
         }
         &:nth-of-type(2) {
          background-color: #c1d3fe;
         }
         &:nth-of-type(3) {
          background-color: #cfdbd5;
         }
         &:nth-of-type(4) {
          background-color: #4DB380;
         }
      }
    }
    .boxPercentage {
      position: absolute;
      right: 0;
      top: 1.8rem;
      right: 1%;
      font-family: HelveticaNeueBold;
    }
    .boxAmount {
      font-size: 1.5rem;
      font-family: HelveticaNeueBold;
    }
    .boxPercentage {
      font-size: 0.8rem;
      color: #ff1b1b;
      &.greenText {
        color: green;
      }
    }
    .boxLabel {
      font-size: 0.9rem;
      color: #000;
    }
  }
  .gridTableSection {
    position: relative;
    margin-bottom: 20px;
  }
  .noDataMsg {
    text-align: center;
    font-family: HelveticaNeueBold;
  }
  @media only screen and (min-width: 1220px) {
    .gridContainer {
      width: 1180px;
    }  
  }