.stackedBarChartWrapper {
    margin-left: -3rem;
    .stackedBarChart {
        width: 100%;
        height: 400px;
        margin: auto;
        max-width: 100%;
        min-width: 1200px;
        .piePath.activeRow {
            stroke: #6c6767;
            filter: drop-shadow(0px 0px 2px #000);
        }
        .recharts-xAxis.xAxis {
            tspan {
                font-size: 0.65rem;
                font-family: HelveticaNeueBold;
                color: #222;
            }
        }
        .recharts-yAxis.yAxis {
            text {
                font-family: HelveticaNeueBold;
                color: #222;
                font-size: 0.65rem;
            }
        }
    }
.recharts-default-legend {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}
}
.boldFont {
    font-family: 'HelveticaNeueBold';
}
.stackedBarCustomToolTip {
    background-color: #fff;
    padding: 0.5rem;
    h4 {
        margin-bottom: 0.25rem;
    }
    p {
        margin-bottom: 0.35rem;
    }
    > div {
        display: flex;
        flex-direction: column-reverse;
    }
    
}
#competitorPrices {
    &.gridTableWrapper .itemCountDiv img {
        position: relative;
        left: 1rem;
    }
}
.modelPopup  {
    &.priceDetailPopup {
        width: 60rem;
        max-width: 80%;
        padding-top: 2rem;
        min-height: 15rem;
        .close.icon {
            top: -0.25rem !important;
        }
    }
}
.priceDetailPopup {
    .gridTable {
        max-height: 20rem;
        table {
            tbody {
                tr {
                    td {
                        background-color: #c9ada7;
                        &:nth-of-type(3) {
                            max-width: 8rem;
                        }
                    }
                    &:nth-of-type(odd) {
                        td {
                            background-color: #b0d0d3;
                        }
                    }
                    
                }
            }
        }
    }
}