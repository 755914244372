@font-face {
    font-family: Antonio-Bold;
    src: url('../../fonts/Antonio-Bold.ttf'),
  }
  @font-face {
    font-family: Antonio-Medium;
    src: url('../../fonts/Antonio-Medium.ttf'),
  }
  @font-face {
    font-family: Antonio-Regular;
    src: url('../../fonts/Antonio-Regular.ttf'),
  }
  @font-face {
    font-family: Antonio-SemiBold;
    src: url('../../fonts/Antonio-SemiBold.ttf'),
  }
  @font-face {
    font-family: Arimo-Bold;
    src: url('../../fonts/Arimo-Bold.ttf'),
  }
  @font-face {
    font-family: Arimo-Medium;
    src: url('../../fonts/Arimo-Medium.ttf'),
  }
  @font-face {
    font-family: Arimo-Regular;
    src: url('../../fonts/Arimo-Regular.ttf'),
  }
  @font-face {
    font-family: Arimo-SemiBold;
    src: url('../../fonts/Arimo-SemiBold.ttf'),
  }


  $red-bg-color:#c7112d;
  $dropDown-bg-color:#1c4871;


  @mixin antoSemiBold {
    font-family: Antonio-SemiBold;
  }
  @mixin antoBold {
    font-family: Antonio-Bold;
  }
  @mixin antoRegular {
    font-family: Antonio-Regular;
  }
  @mixin antoMedium {
    font-family: Antonio-Medium;
  }
  @mixin arialSemiBold {
    font-family: Arimo-SemiBold;
  }
  @mixin arialBold {
    font-family: Arimo-Bold;
  }
  @mixin arialRegular {
    font-family: Arimo-Regular;
  }
  @mixin arialMedium {
    font-family: Arimo-Medium;
  }