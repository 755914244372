.PriceSliderWrapper {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    min-height: auto !important;
    flex-direction: column;
    > div {
      display: flex;
      flex-wrap: wrap;
      max-width: 48rem;
    }
    .priceMsgBlock {
      display: flex;
      font-family: HelveticaNeueBold;
      flex-direction: column;
      text-align: right;
      padding: 0.5rem 0;
       .blackMsg {
        font-size:11px;
        color: #000;
        background-color: #8dc7eb;
        padding: 0.25rem;
        font-style: italic;
       }
      .redError {
        color: #e20b0b;
        margin-bottom: 5px;
      }
      .orangeError {
        color: #eea722;
        margin-bottom: 5px;
      }
    }
    .rangeSlider {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 2rem 0 0;
        button {
           width: 5rem;
           background-color: #046daa;
           color: #f1f0f0;
           border: 0;
           border-radius: 2px;
           padding: 0.5rem;
           font-size: 0.9rem;
           margin: 0.5rem auto;
           font-family: HelveticaNeueMedium;
           cursor: pointer;
           line-height: 1;
           &:hover {
            background-color: #138ed6;
           }
           &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
           }
        }

    }
    .priceTable {
      max-width: 35rem;
      &.grayOutTable {
        opacity: 0.5;
      }
      table {
        border-spacing: 0px;
        background: #f4ecec;
        height: 7rem;
        th,td {
          font-size: 0.7rem;
          font-family: HelveticaNeueBold;
          line-height: 1.5;
          text-align: center;
          min-width: 5rem;
          padding: 0.5rem;
          border: 1px solid #222;
        }
        th {
          background-color: #b5c99a;
          color: #222;
        }
        td {
          border: 1px solid #222;
        }
      }
    }
}
.slidecontainer {
 width: 12rem;
 height: 7rem;
 background: #b5c99a;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.defaultSliderContainer {
     opacity: 0.5;
    }
    .priceLable {
      width: 5rem;
      height: 2rem;
      background-color: #fefefe;
      text-align: center;
      line-height: 2rem;
      margin-bottom: 0.5rem;
      border: 0;
    }
    p {
      font-size: 0.9rem;
      font-family: HelveticaNeueBold;
      margin-bottom: 0.25rem;
      line-height: 1;
    }
.slider {
    -webkit-appearance: none;
    width: 90%;
    height: 0.5rem;
    border-radius: 0.5rem;
    background: #fff;
    outline: none;
    opacity: 0.7;
    margin: 0.75rem 0 0.5rem;
    -webkit-transition: .2s;
    transition: opacity .2s;
  
  &:hover {
    opacity: 1;
  }
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #ffad7e;
    cursor: pointer;
  }
  
  &::-moz-range-thumb {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #ffad7e;
    cursor: pointer;
  }
}
}
.priceOverrideWrapper {
  padding: 1.5rem 0;
  min-height: auto !important;
}
.backLinkDiv {
  color: #1475b3;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  text-align: right;
}
.priceOverrideGrid {
  margin: auto;
  text-align: center;
  table {
    width: 100%;
  }
  .noDataMsg {
    font-size: 12px;
    font-style: italic;
    background: #33afd1;
    display: inline-block;
    padding: 0.25rem;
  }
  .gridTable {
    height: auto;
    margin-bottom: 1rem;
    max-height: 200px;
  }
}
a {
  cursor: pointer;
}
.nonTvMsg {
  color:red;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  margin-left: 8rem;
}