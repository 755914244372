@charset "UTF-8";
@font-face {
  font-family: HelveticaNeue;
  src: url('../src/fonts/HelveticaNeue Light.woff2');
  src: url("../src/fonts/HelveticaNeueCyr-Light.eot"),
  url("../src/fonts/HelveticaNeueCyr-Light.woff") format("woff"); 
}
@font-face {
  font-family: HelveticaNeueMedium;
  src: url('../src/fonts/HelveticaNeue Medium.woff2'),
  url("../src/fonts/Helvetica Neu Medium.woff") format("woff");
}
@font-face {
  font-family: HelveticaNeueBold;
  src: url('../src/fonts/Helvetica Neue Bold.eot'),
  url("../src/fonts/Helvetica Neu Bold.woff") format("woff");
}
@font-face {
  font-family: Franchise;
  src: url('../src/fonts/Franchise-Bold.woff2');
  src: url("../src/fonts/Franchise-Bold.eot"),
  url("../src/fonts/Franchise-Bold.woff") format("woff");
}
@font-face {
  font-family: Antonio-Bold;
  src: url('../src/fonts/Antonio-Bold.ttf'),
}
@font-face {
  font-family: Antonio-Medium;
  src: url('../src/fonts/Antonio-Medium.ttf'),
}
@font-face {
  font-family: Antonio-Regular;
  src: url('../src/fonts/Antonio-Regular.ttf'),
}
@font-face {
  font-family: Antonio-SemiBold;
  src: url('../src/fonts/Antonio-SemiBold.ttf'),
}
body {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue;
  background-color: '#f2f2f2';
}
#root {
  min-width: 1000px;
}
.contentContainer {
  min-height: 97vh;
  background: #f2f2f2;
}
.gridContainer {
  width: 90%;
  margin: auto;
  padding: 0.5rem;
}
.gridContainer > section:nth-of-type(1) {
  min-height: 20rem;
}
.footerWrapper {
  background-color: #000000;
  min-height: 3vh;
  display: flex;
  justify-content: center;
  align-items:center;
}
.footerWrapper p {
  color: #fff;
  text-align: center;
}
.displayBlock{
  display: block !important;
}
.displayNone{
  display: none !important;
}
.displayFlex {
  display: flex;
}
.displayInlineFlex {
  display: inline-flex;
}
.impulseMobileDetail {
  display: block;
}
.displayInline {
  display: inline;
}
.disabled{
  cursor: not-allowed;
  color: #919191;
  text-decoration: none;
}

.styled-cb{
  -webkit-appearance: none;
}
.styled-cb + label{  
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  font-weight: bold;
}
.styled-cb:checked + label:after{
  content: '✔';
}
.styled-cb:not(:checked) + label:after{
  content:'';
}
.commonLoader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top:0;
  bottom: 0;
  left:0;
  right: 0;
  z-index: 5000;
}
.commonLoader .ui.loader::before {
  border: 0.2em solid rgb(56 57 58);
}
.commonLoader .ui.loader::after  {
  border-color: #fff transparent transparent;
}
.authLoader {
  position: fixed;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background: #fff;
  width: 100%;
  top: 0;
  z-index: 5000;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #c8102e;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width:1220px) {
  .gridContainer {
    width: 1180px;
  }
}