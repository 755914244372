.stackedBarChartWrapper .stackedBarChart.suggChart .recharts-yAxis.yAxis > text {
    fill: #222;
}
.noSelectionMsg {
    display: flex;
    align-items: center;
    min-height: 20rem;
    justify-content: center;
    p {
        font-size: 1rem;
        padding: 1rem;
        border: 1px solid #c7112d;
        color: #c7112d;
        font-family: HelveticaNeueBold;
    }
}
.stackedBarChartWrapper .stackedBarChart.suggChart {
    width: 60%;
}
#suggPrice.gridTableWrapper .itemCountDiv img {
    position: relative;
    left: 1rem;
}