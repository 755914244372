@import '../../views/common/common.scss';
.heroHeader {
    background-color: #222;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
    margin: 0 !important;
    .heroHeaderWrapper {
    display: flex;
    height: 100%;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
    }
    h3 {
        font-family: Franchise;
        color: #fff;
        font-size: 2rem;
        line-height: 1.9rem;
        margin: 0;
    }
    .header-top-left {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-right: 0.5rem;
        flex-direction: row;
    }
    .headerLogoImg {
        max-height: 100%;
        max-width: 15rem;
        background-image: url('../../images/NWHLogo.png');
        width: 12rem;
        padding-bottom: 3rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .header-top-right {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .header_icons > div {
        vertical-align: middle;
    }
    .userInfoWrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }
    .header_user_icons_text {
        position: relative;
        padding: 0 0.5rem;
        margin-right: 1rem;
        text-align: right;
        font-size: 1rem;
        line-height: 1.1;
    }
    .headerNameStore {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-around;
        position: relative;
        margin-bottom: 0.5rem;
    }
    .header_user_icons_text p {
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0;
        text-align: right;
        white-space: nowrap;
        font-family: HelveticaNeueMedium;
        line-height: 1;
        
    }
    .headerNameStore p {
        &:nth-of-type(1){
            padding-right: 1rem;
            border-right: 1px solid #fff;
        }
        &:nth-of-type(2) {
            padding-left: 1rem;
        }
    }
    .header_user_icons_text:after {
        content: "";
        width: 1px;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        right: 0;
    }
    .header_user_icons {
        display: flex;
        justify-content: space-around;
        align-items: baseline;
        white-space: nowrap;
        gap:0.75rem;
        flex-wrap: wrap;

        p {
            color: #fff;
            padding: 0.25rem;
            margin-bottom: 0;
            font-family: HelveticaNeueMedium;
            cursor: pointer;
        }
    }
    #user-menu {
        margin-top: 0 !important;
    }
}
.mainMenuWrapper {
    background: $red-bg-color;
    position: sticky;
    top: 0;
    z-index: 10;
    > div {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    padding: 0.5rem;
    a {
        color: #000;
        font-family: HelveticaNeueBold;
        font-size: 1rem;
        transition: 0.35s;

        &:hover {
            color: #fff;
        }
        &.activeMenu {
            color: #fff;
        }
     }
    }
}

.modelPopup {
    max-width: 20rem;
    top: 10rem;
    h3 {
        margin: 0.5rem 0;
    }
    &.needHelpPopup {
        max-width: 50rem;
    }
  }
  .ui.modal.modelPopup>.content {
    background: #f2f2f2;
  }
  .modelPopup .close.icon {
    color: #000;
    top: 0.5rem!important;
    right: 0.5rem!important;
  }
  .changeStoreContainer {
    font-family: Arial, Helvetica, sans-serif;
  }
  .changeStoreContainer h3 {
    font-family: AntonioBold;
    font-size: 1.5rem;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .changeStoreFields {
    display: flex;
    margin: 1rem 0;
  }
  .changeStoreFields input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .changeStoreFields button {
    font-family: AntonioBold;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #ada8a8;
    background: #c6112d;
    color: #fff;
    min-width: 4rem;
    cursor: pointer;
  }
  .changeStoreFields select {
    outline: none !important;
  }