@import '../../views/common/common.scss';
.gridTable {

    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    max-height: 34rem;
    &::-webkit-scrollbar {
        width: 0.5rem;
        cursor: pointer;
      }
       
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
       
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid #fff;
        cursor: pointer;
      }
    table {
        border-spacing: 0px;
        background: #f2f2f2;
        thead th{
            position: sticky;
            top: 0;
            padding-right: 1rem;
            font-size: 0.6rem;
            cursor: pointer;
            z-index: 10;
            i {
                position: absolute;
                right: 0;
            }

        }
        tr {
            th, td {
                &:nth-of-type(1) {
                    width: 5rem;
                }
            }
        }
        tbody {
            tr {
                cursor: pointer;
                &:nth-of-type(1) td{
                    border-top: 0;
                }
                &.activeRow {
                    td {
                        background-color: #979797;
                    }
                }
            }
        }
        tfoot td {
            position: sticky;
            bottom: 0;
            font-family: HelveticaNeueBold;
            font-size: 0.75rem;
            z-index: 10;
            &:nth-of-type(1){
                background-color: #222;
                color: #fff;
                padding: 0.3rem 0.25rem;
               
            }
            &:nth-of-type(2) {
                background-color: #e9c46a;
            }
            &:nth-of-type(3) {
                background-color: #e9c46a;
            }
            &:nth-of-type(4) {
                background-color: #e9c46a;
            }
            &:nth-of-type(5) {
                background-color: #b0d0d3;
            }
           
            &:nth-of-type(6) {
                background-color: #b0d0d3;
            }
            &:nth-of-type(7) {
                background-color: #b0d0d3;
            }
            &:nth-of-type(8) {
                background-color: #e9c46a;
            }
            &:nth-of-type(9) {
                background-color: #e9c46a;
            }
            &:nth-of-type(10) {
                background-color: #e9c46a;
            }
            &:nth-of-type(11) {
                background-color: #b0d0d3;
            }
            &:nth-of-type(12) {
                background-color: #b0d0d3
            }
            &:nth-of-type(13) {
                background-color: #b0d0d3;
            }
        }
    }
    th {
        padding: 0.5rem;
      
    }
    td {
        padding: 0.15rem ;
    }
    th,td {
        font-size: 0.7rem;
        font-family: HelveticaNeueMedium;
        line-height: 1.1;
        text-align: center;
        border: 1px solid #fff;
        height: 1.75rem
    }
    .descrptionRow{
        min-width: 15rem;
    }
    tr {
         > th {
            background-color: #222;
            color: #fffefe;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.25;
         }
        >td {
            &:nth-of-type(1) {
                background-color: #cfdbd5;
            }
            &:nth-of-type(2) {
                background-color: #cfdbd5;
            }
            &:nth-of-type(3) {
                background-color: #cfdbd5;
            }
            &:nth-of-type(4) {
                background-color: #cfdbd5;
            }
            &:nth-of-type(5) {
                background-color: #c9ada7;
            }
            &:nth-of-type(6) {
                background-color: #ef7a85;
            }
            &:nth-of-type(7) {
                background-color: #e9c46a;
            }
            &:nth-of-type(8) {
                background-color: #e9c46a;
            }
            &:nth-of-type(9) {
                background-color: #e9c46a;
            }
            &:nth-of-type(10) {
                background-color: #b0d0d3;
            }
           
            &:nth-of-type(11) {
                background-color: #b0d0d3;
            }
            &:nth-of-type(12) {
                background-color: #b0d0d3;
            }
            &:nth-of-type(13) {
                background-color: #e9c46a;
            }
            &:nth-of-type(14) {
                background-color: #e9c46a;
            }
            &:nth-of-type(15) {
                background-color: #e9c46a;
            }
            &:nth-of-type(16) {
                background-color: #b0d0d3;
            }
            &:nth-of-type(17) {
                background-color: #b0d0d3;
            }
            &:nth-of-type(18) {
                background-color: #b0d0d3;
            }


        }
    }

}
.gridTableWrapper {
    position: relative;
    .countPerPage {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem;
        align-items: flex-end;
        span {
            font-size: 0.8rem;
            font-family: HelveticaNeueBold;
            display: inline-block;
            padding-right: 0.5rem;
        }
        select {
            cursor: pointer;
        }
        .itemCountDiv {
            display: flex;
            align-items: flex-end;
            gap: 1rem;
            img {
                max-width: 175px;
                max-height: 175px;
            }
            p {
                margin: 0;
                font-size: 1rem;
               font-family: HelveticaNeueMedium;
                
            }
            h3 {
                margin: 0;
                font-family: HelveticaNeueBold;
            }
        }
    }
   
        .itemCountDiv img {
            position: absolute;
            left: 7rem;
             bottom: 1rem;
        }

}

.paginationWrapper {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    
    .ui.pagination.menu .active.item {
        color: #de5368;
        font-family: HelveticaNeueBold;
        background-color: #f2f2f2;
    }
    .ui.pagination.menu .item {
        padding: 0.5rem;
        display: inline-block;
        
        color: #020202;
    }
    .ui.pagination.menu {
        min-height: 1rem;
        background: #f2f2f2;
        border: 0;
        box-shadow: none;
    }
    .ui.menu .item:before {
        display: none;
    }
}

.whatIfSubmit {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    flex-basis: 100%;
    flex-grow: 3;
    button {
        background: #046daa;
        font-size: 1rem;
        font-family: HelveticaNeueMedium;
        border: 0;
        border-radius: 2px;
        padding: 0.5rem 0.75rem;
        color: #fff;
        line-height: 1;
        cursor: pointer;
        transition: 0.25s;
        &:hover 
        {
            background-color: #138ed6;
        }
    }
}
.overrideConfirmation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > div {
        margin: 0 1rem;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        font-family: HelveticaNeueBold;
        span {
            display: inline-block;
            padding: 0 0.5rem;
        }
        label {
            padding: 0 0.35rem;
            cursor: pointer;
        }
        input {
            cursor: pointer;
        }
       
    }
    button {
        background: #046daa;
        font-size: 1rem;
        font-family: HelveticaNeueMedium;
        border: 0;
        border-radius: 2px;
        padding: 0.5rem 0.75rem;
        color: #fff;
        line-height: 1;
        cursor: pointer;
        transition: 0.25s;
        &:hover {
            background-color: #138ed6;
        }
    }
}
.removeItemBtn {
    background: #c7112d;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-family: HelveticaNeueBold;
    padding: 0.4rem 0.5rem;;
    border: 0;
    border-radius: 2px;
    color: #fff;
    line-height: 1;
    cursor: pointer;
    transition: 0.25s;
    margin-left: 1rem;
    margin-right: 1rem;
    &:hover {
        background-color: #2542b6;
    }
}
#TabFive, #competitorPrices, #suggPrice {
    + .gridTable {
        tr > th:nth-of-type(15) {
            width: 6rem;
        }
        tr > {
            td
            td:nth-of-type(7), td:nth-of-type(8) {
                background-color: #c9ada7;
            }
            td:nth-of-type(9),  td:nth-of-type(10), 
            td:nth-of-type(11),td:nth-of-type(12),td:nth-of-type(13)  {
                background-color: #e9c46a;
            }
            td:nth-of-type(14),
            td:nth-of-type(15),td:nth-of-type(16),td:nth-of-type(17)  {
                background-color: #b0d0d3;
            }
        } 
        tfoot {
            tr {
                td:nth-of-type(1) {
                    background-color: #000;
                }
            }
        }
    } 
}
.downloadBtn {
    background: #2899cf;
    color: #fff;
    font-family: HelveticaNeueBold;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 5px;
    margin-right: 1rem;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
        background: #1674a1;
    }
    &:disabled {
        background-color: #33829f;
        opacity: 0.5;
    }
}
#storePricingMix , #priceView
{
    .gridTable {
        table {
            width: 100%;
        }
    }
}