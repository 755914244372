@import '../../views/common/common.scss';
.donutChartWrapper {
    font-family: HelveticaNeueBold;
    h4 {
      margin-bottom: 0;
      font-size: 1.1rem;
      margin-left: 10%;
      @include antoBold;
      color: #c7112d;
    }
    .recharts-text {
      font-size: 0.7rem;
    }
    .recharts-layer {
      line {
        stroke: #ccc;
      }
      text {
        fill: #222;
        font-size: 0.6rem;
        pointer-events: none;
        &.smallDonutText {
          font-family: 'HelveticaNeue';
          fill: #fff;
          font-size: 0.8rem;
          pointer-events: none;
        }
      }
    }
    .donutText {
        font-size: 0.5rem;
    }
    path,.recharts-layer {
        outline: none;
        
      }
      .recharts-pie-sector {
        path {
          cursor: pointer;
        }
      }
    .recharts-legend-item-text {
        span {
            font-size: 0.9rem;
        }
    }
}
.priceChartWrapper {
    position: relative;
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .toggleBtn {
      margin-bottom: 0;
      margin-top: 0.5rem;
      margin-left: 0;
        span {
          font-family: HelveticaNeueBold;
        }
    }
    .barChartWrapper {
        width: 400px;
        min-width: 400px;
    }
}
.priceChangeSection {
  .chartWrapper {
    height: 275px;
  }
  .priceChartWrapper {
    margin-top: 0;
    padding-top: 0;
  }
}
  .custom-tooltip.dountTooltip {
    background-color: #fff;
        padding: 0.4rem;
        border: 1px solid #ddd;
        min-width: 100px;
        p {
          font-size: 0.75rem;
          color: #222;
          margin: 0;
          font-family: HelveticaNeueBold;
        }
  }
  .donutChartWrapper  {
    width: 100%;
    height:100%;
    .recharts-legend-wrapper {
      height: 200px !important;
      margin-top: 5px;
      margin-left: 120px;
      ul {
        display: flex;
        flex-direction: column-reverse;
      }
      li {
        margin-bottom: 0.5rem;
        span {
          transition: 0.25s;
          &.activeLegend {
          transform: scale(1.25);
        }
      }
      }
      
    }
    .piePath.activeRow {
      transform: scale(1.05);
      transform-origin: center;
    } 
  }
.gridNoDataBox {
  color: #c7112d;
  text-align: center;
  padding-top: 1rem;
  h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
  }
}